import * as amplitude from '@amplitude/analytics-browser'
import {sessionReplayPlugin} from '@amplitude/plugin-session-replay-browser'
import {GoogleAnalytics, sendGAEvent} from '@next/third-parties/google'
import {Analytics as VercelAnalytics} from '@vercel/analytics/react'
import {SpeedInsights} from '@vercel/speed-insights/next'
import {usePathname} from 'next/navigation'
import {useReportWebVitals} from 'next/web-vitals'
import {useEffect, useState} from 'react'

const AMPLITUDE_KEY = '5cb7d2c428d776a1f879ec3d1f8c26d2'
const GA_MEASUREMENT_ID = 'G-K24ZV69MZR'

interface Props {
  children: React.ReactNode
}

function AnalyticsHoCProvider({children}: Props) {
  const [previousPathname, setPreviousPathname] = useState<string | null>(null)
  const pathname = usePathname()

  useReportWebVitals(metric => {
    if (typeof window !== 'undefined') {
      sendGAEvent({
        event: metric.name,
        value: Math.round(
          metric.name === 'CLS' ? metric.value * 1000 : metric.value,
        ),
        event_category: 'Web Vitals',
        event_label: metric.id,
        non_interaction: true,
      })
    }
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const sessionReplayTracking = sessionReplayPlugin()

      amplitude.add(sessionReplayTracking)

      amplitude.init(AMPLITUDE_KEY, {
        autocapture: {
          sessions: true,
          attribution: true,
          elementInteractions: false,
          fileDownloads: false,
          formInteractions: false,
          pageViews: false,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      amplitude.logEvent('navigate.page', {
        metadata: {
          url: window.location.href,
          path: pathname,
          title: document.title,
          screen: `${window.screen.width}x${window.screen.height}`,
          language: navigator.language,
          platform: navigator.platform,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          user_agent: navigator.userAgent,
          navigation_from_page: previousPathname,
          navigation_to_page: pathname,
        },
        device_info: {
          screen_resolution: `${window.screen.width}x${window.screen.height}`,
          screen_viewport: `${window.innerWidth}x${window.innerHeight}`,
          screen_viewport_resolution: `${window.innerWidth}x${window.innerHeight}`,
          screen_viewport_viewable_area: `${window.innerWidth}x${window.innerHeight}`,
        },
      })

      setPreviousPathname(pathname)
    }
  }, [pathname, previousPathname])

  return (
    <>
      <GoogleAnalytics gaId={GA_MEASUREMENT_ID}/>
      <VercelAnalytics/>
      <SpeedInsights/>
      {children}
    </>
  )
}

export {AnalyticsHoCProvider}
