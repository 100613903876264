import '@radix-ui/themes/styles.css'

import {Theme as RadixUiTheme} from '@radix-ui/themes'
import {CommandBar} from '@/components'
import {ThemeProvider} from '@/contexts'
import {AnimatePresence} from 'framer-motion'
import {GeistSans} from 'geist/font/sans'
import {AnalyticsHoCProvider} from '@/hocs/analytics'
import {useTheme} from '@/hooks'
import type {AppProps} from 'next/app'
import {Suspense, useEffect, useState} from 'react'
import {ThemeProvider as StyledComponentsThemeProvider} from 'styled-components'
import GlobalStyles from '@/styles/global'
import ProgressBar from 'nextjs-progressbar'

export default function App({Component, pageProps}: AppProps) {
  const {theme, updateTheme} = useTheme()
  const [defaultTheme, setDefaultTheme] = useState<'dark' | 'light' | null>(
    null,
  )

  useEffect(() => {
    setDefaultTheme(theme.title === 'DARK_THEME' ? 'dark' : 'light')
  }, [theme])

  return (
    <AnalyticsHoCProvider>
      <ProgressBar
        color={theme.colors.highlightColor}
        options={{
          showSpinner: false,
          easing: 'ease',
          speed: 200,
          trickleSpeed: 200,
        }}
        height={3}
        startPosition={0.3}
        stopDelayMs={200}
        showOnShallow={true}
      />
      <AnimatePresence
        mode="wait"
        initial={false}
        onExitComplete={() => window.scrollTo(0, 0)}
      >
        <RadixUiTheme appearance={defaultTheme ? defaultTheme : 'dark'}>
          <StyledComponentsThemeProvider theme={theme}>
            <main className={GeistSans.className}>
              <ThemeProvider updateTheme={updateTheme}>
                <GlobalStyles/>

                <Suspense>
                  <CommandBar>
                    <Component {...pageProps} />
                  </CommandBar>
                </Suspense>
              </ThemeProvider>
            </main>
          </StyledComponentsThemeProvider>
        </RadixUiTheme>
      </AnimatePresence>
    </AnalyticsHoCProvider>
  )
}
